import { cn } from '#app/utils/misc'

export function Chip(props: {
	children?: React.ReactNode
	className?: HTMLSpanElement['className']
}) {
	return (
		<span
			className={cn(
				'border-[1px] inline-flex items-center rounded-full bg-foreground/10 px-2 py-1 text-xs text-foreground/60',
				props.className,
			)}
		>
			{props.children}
		</span>
	)
}
